// CMP
(() => {
  const isCMPDisabled =
    window.location.hash === '#b5efd781-75dc-44c3-bb7b-68e1263c410c' ||
    (window.tv2 && window.tv2.cmp && window.tv2.cmp.disabled);
  if (isCMPDisabled) {
    return;
  }

  const isOnTv2Dk = window.location.hostname.match(/tv2.dk$/);
  const domain = isOnTv2Dk
    ? '40f8ffd8-cef1-4fd6-8c72-3670f4b67171'
    : '40f8ffd8-cef1-4fd6-8c72-3670f4b67171-test';

  window.tv2.utils.loadScript(
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    null,
    {
      'data-domain-script': domain,
      'data-language': 'da',
      'data-dLayer-ignore': 'true',
    },
  );

  const log = window.tv2.utils.setupLog('🍪 CMP');

  const tcfReady = () => {
    window.__tcfapi('addEventListener', 2, (data) => {
      const { eventStatus, tcString, purpose } = data || {};
      if (
        ['useractioncomplete', 'tcloaded'].indexOf(eventStatus) !== -1 &&
        tcString
      ) {
        log.info('Consent settings received', {
          eventStatus,
          tcString,
          purpose,
        });
        // Push full consent ("Acceptér Alle") to tv2messages
        const consents = Object.values(purpose.consents);
        const hasFullConsent =
          consents.length === 11 && consents.indexOf(false) === -1;
        if (hasFullConsent) {
          log.info('User has given full consent: ', hasFullConsent);
        }
        window.tv2messages.publish('hasConsent', hasFullConsent);
        window.tv2messages.publish('individualConsent', purpose.consents);
      }
    });
  };

  window.addEventListener('click', (event) => {
    const eventName = event.target.getAttribute('data-cmp') || false;
    if (eventName === 'cpc-open' && window.__tcfapi) {
      event.stopPropagation();
      if (window.OneTrust) {
        window.OneTrust.ToggleInfoDisplay();
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.__tcfapi('displayConsentUi', 2, () => {});
    }
  });

  const awaitTCF = () => {
    if (typeof window.__tcfapi === 'function') {
      log.info('TCF module loaded', { ...window.tv2.cmp });
      return tcfReady();
    }
    return setTimeout(awaitTCF, 10);
  };
  awaitTCF();
})();
